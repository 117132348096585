table.calendar.mobile td.day {
	padding: .2rem;
	height: 50px;
}
table.calendar.mobile td.day>div .weather>svg {
	transform: scale(.6);
}
table.calendar.mobile td.day .dayNumber>button {
	font-size: .6em;
}
table.calendar td.day>.celestialBodies {
	margin-top: 0px;
}
table.calendar.mobile td.day>.celestialBodies>div {
	width: 12px;
	height: 12px;
	margin: 1px;
}
table.calendar.mobile td.day>.celestialBodies>div>.celestialBody>img {
	width: 12px;
}
