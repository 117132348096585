body {
	overflow-y: scroll;
}

div.card div.card-body.settings {
	overflow-x: none;
	overflow-y: auto;
	height: 600px;
}
table.calendar {
}
table.calendar>thead>tr {
	text-align: center;
}
table.calendar td.day {
	height: 100px;
}
table.calendar td.day.emptyDay {
	background-color: #fafafa;
}
table.calendar td.day:hover {
	background-color: #cfe2ff !important;
}
table.calendar td.day.hasCelestialBodies {
	background-color: #eef6f3;
}
table.calendar td.day.today {
	background-color: #e6f0ff;
}
table.calendar td.day.today>div>a.dayNumber {
	color: black;
	font-weight: bold;
}
table.calendar td.day .dayNumber {
	float: right;
}
table.calendar td.day .dayNumber>button {
	color: gray;
	text-decoration: none;
	padding: 0;
}
table.calendar td.day .dayNumber>button::after {
	display: none;
}
table.calendar td.day>.celestialBodies {
	margin-top: 10px;
}
table.calendar td.day>.celestialBodies>div {
	display: inline-block;
	width: 32px;
	height: 32px;
	margin: 2px;
}
table.calendar td.day>.celestialBodies>div>.celestialBody {
	display: block;
}
table.calendar td.day>.celestialBodies>div>.celestialBody>img {
	width: 32px;
}
table.calendar td.day>.celestialBodies>div.none {
	text-align: center!important;
	font-size: .7em;
	color: #A2A4A5;
	width: 100%!important;
}

.leaflet-container {
	height: 250px
}

label {
	text-transform: uppercase;
	font-weight: bold;
	color: gray;
	font-size: .7em;
}
label::first-letter {
	font-size: 1.3em;
}
.regular>label {
	text-transform: inherit;
	font-weight: inherit;
	color: inherit;
	font-size: inherit;
}
.regular>label::first-letter {
	font-size: inherit;
}
.tooltip-inner {
	text-align: left;
}